@import "../../App";
$maxWidth: 1500px;

.videoEmbedContainer {
  max-width: $maxWidth;
  margin: 0 auto;
  overflow:hidden;
  padding-bottom:56.25%;
  position:relative;
  height:0;
  border: 4px solid $lighter-gray;
  box-sizing: border-box;

  iframe {
    left:0;
    top:0;
    height:100%;
    width:100%;
    position:absolute;
  }
}