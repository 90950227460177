@import "../../App";
$citiesNumber: 6;

#citySelector {
  height: 100vh;
  overflow: hidden;

  .citySelectorContainer {
    padding-top: 60px;
    display: flex;
    height: 100%;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-evenly;
    align-items: center;
    align-content: center;
    box-sizing: border-box;
    @include m {
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      align-content: flex-start;
    }

    .citySelectorContent {
      display: grid;
      flex: 1 1 auto;
      height: 100%;
      width: (100% / $citiesNumber);
      max-width: (100% / $citiesNumber);
      border-right: 4px solid #ffffff;
      box-sizing: border-box;
      position: relative;
      transition: 1.5s ease-out;
      &:first-child {
        border-left: 4px solid #ffffff;
      }
      &.us {
        background: url(../../images/cities/cities_us.jpg) center center
          no-repeat;
        @include m {
          background-size: cover;
        }
      }
      &.sd {
        background: url(../../images/cities/cities_sd.jpg) center center
          no-repeat;
        @include m {
          background-size: cover;
        }
      }
      &.mi {
        background: url(../../images/cities/cities_mi.jpg) center center
          no-repeat;
        @include m {
          background-size: cover;
        }
      }
      &.la {
        background: url(../../images/cities/cities_la.jpg) center center
          no-repeat;
        @include m {
          background-size: cover;
        }
      }
      &.ny {
        background: url(../../images/cities/cities_ny.jpg) center center
          no-repeat;
        @include m {
          background-size: cover;
        }
      }
      &.dc {
        background: url(../../images/cities/cities_dc.jpg) center center
          no-repeat;
        @include m {
          background-size: cover;
        }
      }
      @include m {
        flex: 1 1 auto;
        width: 100%;
        max-width: none;
        height: (100% / $citiesNumber);
        max-height: (100% / $citiesNumber);
        border-bottom: 4px solid #ffffff;
        border-right: none;
        &:first-child {
          border-left: none;
        }
        &:last-child {
          border-bottom: none;
        }
      }
      .cityLogoContainer {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        opacity: 0.5;
        transition: opacity 1s ease-out;
        text-align: center;
        @include m {
          opacity: 0.8;
        }

        .cityLogo {
          display: inline-block;
          width: 100%;
          max-width: 236px;
          min-width: 150px;
          @include l {
            min-width: 130px;
          }
          @include m {
            max-width: 110px;
            min-width: unset;
          }
          @include s {
            max-width: unset;
            min-width: unset;
            max-height: 80px;
            width: auto;
          }
        }

        .cityLogoText {
          display: block;
          font-weight: bold;
          font-size: 24px;
          color: #ffffff;
          text-align: center;
          margin-top: 20px;
          transform: translateY(120vw);
          transition: 0.3s;
          position: absolute;
          text-align: center;
          width: 100%;
          @include m {
            transform: none;
            font-size: 18px;
          }
          @include s {
            display: none;
          }
        }
      }
      &:hover {
        width: (100% / $citiesNumber) + 10%;
        max-width: 450px;
        transition: 0.5s ease-out;
        transition-delay: 0.5s;
        .cityLogoContainer {
          opacity: 1;
          transition: opacity 1s;
          .cityLogo {
            animation: rotate 0.7s ease-in-out 0.5s;
          }
          .cityLogoText {
            transform: none;
            transition: 0.3s;
          }
        }
        @include m {
          width: 100%;
          max-width: none;
          transition: none;
        }
      }
    }
  }
  @keyframes rotate {
    0% {
      transform: rotateY(360deg);
    }
    100% {
      transform: rotateY(0deg);
    }
  }
}
