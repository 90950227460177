@import "../../App.scss";

header {
  width: 100%;
  box-sizing: border-box;
  max-height: 80vh;
  background-color: $orange;
  overflow: hidden;
  position: relative;

  .BgVideoContainer {
    position: relative;
    z-index: 1;
    width: 100%;
    height: 100%;
    opacity: 0.4;

    #BgVideo {
      width: 100%;
    }
  }
  .logoContainer {
    position: absolute;
    z-index: 10;
    left: 20px;
    top: 20px;
    figure {
      margin: 0;
    }
    @include l {
      bottom: 30px;
      top: unset;
    }
    @include s {
      bottom: 30px;
      left: 0;
      transform: scale(0.8);
    }
  }
  .MenuContainer {
    position: absolute;
    z-index: 10;
    top: 30px;
    right: 40px;
    @include l {
      left: 0;
      right: unset;
    }
    @include s {
      display: none;
    }
  }
  .sloganContainer {
    position: absolute;
    z-index: 1;
    top: 50%;
    left: 50%;
    width: 100%;
    text-align: center;
    transform: translate(-50%, -50%);
    @include s {
      display: none;
      top: 10px;
      left: 0;
      text-align: center;
      transform: none;
    }

    h1 {
      display: inline-block;
      width: 100%;
      margin: 0;
      color: #ffffff;
      font-size: 60px;
      font-weight: bold;
      text-align: center;
      @include l {
        font-size: 50px;
      }
      @include m {
        font-size: 30px;
      }
    }

    .ghostButton {
      margin-top: 10px;
      font-size: 36px;
      padding: 15px 20px;
      @include l {
        font-size: 26px;
      }
      @include m {
        font-size: 20px;
      }
    }
  }
}
