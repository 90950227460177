@import "../../App";

#headerMenu {
  position: fixed;
  display: block;
  width: 100%;
  z-index: 100;
  background-color: $orange;
  height: 60px;
  box-shadow: 0 4px 18px #2a2a2a;

  .dividerContainerTop {
    top: 50px;
    @include m {
      top: 55px;
    }
  }

  .headerContent {
    position: relative;
    z-index: 110;

    .headerLogoContainer {
      display: inline-block;
      position: absolute;
      top:20px;
      width: 180px;
      height: 60px;
      vertical-align: middle;
      padding-left: 30px;
      &:before {
        display: inline-block;
        content: "";
        height: 100%;
        vertical-align: middle;
        text-align: right;
      }
      @include l {
        top:10px;
      }
      @include m {
        top:5px;
        padding-left:10px;
      }
      @include s {
        max-width: 120px;
      }

      .headerLogo {
        display: inline-block;
        width: 100%;
        vertical-align: middle;
      }
    }
    .titleText {
      display: inline-block;
      vertical-align: middle;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%,50%);
      @include m {
        right:10px;
        left: unset;
        transform: translate(0,50%);
      }

      h1 {
        display: inline-block;
        vertical-align: middle;
        margin: 0;
        color: #ffffff;
        text-transform: uppercase;
        font-size: 28px;
        &:before {
          display: inline-block;
          content: "";
          height: 100%;
          vertical-align: middle;
          text-align: right;
        }
        @include s {
          font-size: 28px;
        }
      }
    }
  }
}
