@import "../../App.scss";

.ContactUs {
  box-sizing: border-box;
  &:after {
    content: "";
    background: url(../../images/contact_us/contact_us_bottom_bg.jpg) bottom right no-repeat;
    opacity: 0.7;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    position: absolute;
    z-index: -2;
  }


  .ContentContainer {
    padding: 60px 40px;
    position: relative;
    box-sizing: border-box;
    &:after {
      content: "";
      background: url(../../images/contact_us/contact_us_bg.jpg) bottom left
        no-repeat;
      opacity: 0.15;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      position: absolute;
      z-index: -1;
    }
    @include s {
      padding: 40px 20px;
    }

    .subTitle {
      &.orange {
        color: $orange;
        font-weight: bold;
        font-style: italic;
        margin: 0 0 30px 0;
      }
    }

    p {
      text-align: center;
    }
    .ContactCardsContainer {
      display: flex;
      margin: 40px 0 100px 0;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: center;
      align-items: flex-start;
      box-sizing: border-box;
      @include m {
        margin: 40px 0 40px 0;
      }

      .ContactCard {
        padding: 20px;
        margin-right: 40px;
        border-radius: 0;
        border-bottom: 5px solid $lighter-gray;
        transition: 1s;
        &:last-child {
          margin-right: 0;
        }
        &:hover {
          border-color: $orange;
          transition: 1s;
        }
        @include m {
          width: 100%;
          margin: 0;
          padding: 20px 0 20px 0;
        }
        .avatarImage {
          display: inline-block;
          vertical-align: middle;
          width: 80px;
          height: 80px;
          margin-right: 10px;
          border: 3px solid $light-gray;
          border-radius: 50%;
          overflow: hidden;
          opacity: 1;
          &.man {
            background: url(../../images/contact_us/profile_man.jpg) center
              center no-repeat;
            @include s {
              background-size: 80px 80px;
            }
          }
          &.ajay {
            background: url(../../images/contact_us/profile_ajay.jpg) center
            center no-repeat;
            @include s {
              background-size: 64px 64px;
            }
          }
          &.mark {
            background: url(../../images/contact_us/profile_mark.jpg) center
            center no-repeat;
            @include s {
              background-size: 64px 64px;
            }
          }
          &.arianna {
            background: url(../../images/contact_us/profile_arianna.jpg) center
            center no-repeat;
            @include s {
              background-size: 64px 64px;
            }
          }
          &.woman {
            background: url(../../images/contact_us/profile_woman.jpg) center -10px
              no-repeat;
            @include s {
              background-size: 80px 80px;
            }
          }
          @include m {
            width: 60px;
            height: 60px;
          }
        }
        .personalData {
          display: inline-block;
          vertical-align: middle;
          span {
            display: block;
          }
        }
      }
    }
    .socialMediaContainer {
      display: block;
      margin: 0 0 60px 0;
      text-align: center;

      a {
        display: inline-block;
        margin-right: 20px;
        transition: 1s;
        &:last-child {
          margin-right: 0;
        }
        svg {
          transform: scale(1);
          transition: 1s;
        }
        &:hover {
          svg {
            transform: scale(1.2);
            transition: 1s;
            path {
            fill: $orange;
            transition: .5s;
            }
          }
        }

      }
    }
    .athletesContainer {
      display: flex;
      max-width: 1150px;
      margin: 0 auto;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: center;
      align-items: center;
      align-content: center;
      box-sizing: border-box;
      @include m {
        width: 200px;
      }

      .athletesAvatarContent {
        flex: 0 1 200px;
        margin-right: 40px;
        margin-bottom: 40px;
        &:last-child {
          margin-right: 0;
        }
        @include m {
          margin: 0 0 40px 0;
        }

        &:hover {
          .athletesAvatar {
            transform: scale(1.1);
            border-color: $orange;
            transition: 1s;
          }
          .athletesName {
            color: $orange;
            transition: 1s;
          }
        }

        .athletesAvatar {
          border: 5px solid $lighter-gray;
          border-radius: 50%;
          overflow: hidden;
          transition: 1s;
          &:before {
            display: block;
            content: "";
            margin-top: 100%;
          }
          &.andres {
            background: url(../../images/contact_us/athlete_andres.jpg) center
              center no-repeat;
          }
          &.brett {
            background: url(../../images/contact_us/athlete_brett.jpg) center
              center no-repeat;
          }
          &.carol {
            background: url(../../images/contact_us/athlete_carol.jpg) center
              center no-repeat;
          }
          &.daniel {
            background: url(../../images/contact_us/athlete_daniel.jpg) center
              center no-repeat;
          }
          &.franki {
            background: url(../../images/contact_us/athlete_franki.jpg) center
              center no-repeat;
          }
          &.margi {
            background: url(../../images/contact_us/athlete_margi.jpg) center
              center no-repeat;
          }
          &.nancy {
            background: url(../../images/contact_us/athlete_nancy.jpg) center
              center no-repeat;
          }
        }
        .athletesName {
          display: block;
          margin-top: 10px;
          text-align: center;
          font-weight: bold;
          color: $dark-gray;
          font-size: 26px;
        }
      }
    }
  }
}
