@import "../../App";

.aboutUs {
  position: relative;
  padding: 80px 80px 150px 80px;
  box-sizing: border-box;
  background: url(../../images/about_us/about_us_bg.png) -150px center no-repeat;
  @include m {
    padding: 20px 20px 100px 20px;
  }

  .avatarContainer {
    display: flex;
    max-width: $maxWidth;
    margin: 0 auto;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-between;
    align-items: center;
    align-content: center;
    box-sizing: border-box;
    @include m {
      flex-wrap: wrap;
      justify-content: center;
    }

    .avatarContent {
      flex: 0 1 280px;
      margin-right: 20px;
      &:last-child {
        margin-right: 0;
      }
      @include m {
        margin: 0 0 20px 0;
      }

      .avatarItem {
        width: 100%;
        height: 100%;
        border-radius: 50%;
        border: 7px solid $lighter-gray;
        overflow: hidden;
        //-moz-filter: url("data:image/svg+xml;utf8,<svg xmlns=\'http://www.w3.org/2000/svg\'><filter id=\'grayscale\'><feColorMatrix type=\'matrix\' values=\'0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0 0 0 1 0\'/></filter></svg>#grayscale");
        //-o-filter: url("data:image/svg+xml;utf8,<svg xmlns=\'http://www.w3.org/2000/svg\'><filter id=\'grayscale\'><feColorMatrix type=\'matrix\' values=\'0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0 0 0 1 0\'/></filter></svg>#grayscale");
        //-webkit-filter: grayscale(100%);
        //filter: gray;
        //filter: url("data:image/svg+xml;utf8,<svg xmlns=\'http://www.w3.org/2000/svg\'><filter id=\'grayscale\'><feColorMatrix type=\'matrix\' values=\'0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0 0 0 1 0\'/></filter></svg>#grayscale");
        &:before {
          display: block;
          content: "";
          margin-top: 100%;
        }
        @include l {
          border: 4px solid $lighter-gray;
        }
        &.aboutUs1 {
          background: url(../../images/about_us/about_us_1.jpg) center left
            no-repeat;
        }
        &.aboutUs2 {
          background: url(../../images/about_us/about_us_2.jpg) bottom center
            no-repeat;
        }
        &.aboutUs3 {
          background: url(../../images/about_us/about_us_3.jpg) center center
            no-repeat;
        }
        &.aboutUs4 {
          background: url(../../images/about_us/about_us_4.jpg) center center
            no-repeat;
        }
        &:hover {
          border-color: $orange;
          -moz-filter: none;
          -o-filter: none;
          -webkit-filter: none;
          filter: none;
          transition: 1s;
        }
      }
    }
  }
}
