@import "../../App.scss";

.placeHolderSite {
  height: 100vh;
  background: url(../../images/video_poster.jpg) center center fixed;
  background-size: cover;

  .fullscreenContainer {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background-color: rgba(252, 107, 0, 0.8);
    padding: 0 0 40px 0;
    box-sizing: border-box;
    @include s {
      height: 100%;
    }

    .placeHolderContent {
      flex: 1 1 auto;
      text-align: center;
      padding: 0 40px;

      .textContent {
        max-width: 800px;
        margin: 0 auto;
        p {
          color: #ffffff;
          text-shadow: 0 0 15px #424242;
          a {
            color: $white-background;
            text-decoration: underline;
            transition: 1s;
            &:hover {
              color:#000;
              text-decoration: none;
              transition: 1s;
            }
          }
          @include s {
            font-size: 16px;
          }
        }
        .ghostButton {
          margin-top: 20px;
        }
      }
    }
  }
}
