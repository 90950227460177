@import "../../App";
$maxWidth: 1500px;

.introduction {
  position: relative;
  padding: 20px 80px 150px 80px;
  box-sizing: border-box;
  background-color: #ffffff;
  @include m {
    padding: 20px 20px 100px 20px;
  }

  .textContainer {
    .imageContainer {
      width: 100%;
      text-align: center;
      figure {
        width: 100%;
        margin: 0;
        img {
          width: 100%;
          max-width: 1280px;
        }
      }
    }
  }
  .subTitle {
    margin: 60px 0 40px 0;
  }
}
