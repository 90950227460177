@import "../../App.scss";

.dd-wrapper {
  position: relative;
  z-index: 500;
  display: block;
  width: 220px;
  @include m {
    display: none;
  }
  .dd-header-title {
    color: $white-background;
    font-size: 30px;
    font-weight: bold;
    font-style: italic;
    text-transform: uppercase;
    user-select: none;
    &:hover {
      text-decoration: underline;
    }
    svg {
      vertical-align: middle;
      margin: 0;
      padding: 0;
    }
  }
  .dd-list {
    padding: 0;
    background: rgba(255,255,255,.2);
    li {
      display: block;
      font-size:20px;
      color:$white-background;
      font-weight: normal;
      text-align: center;
      text-transform: uppercase;
      margin: 0 0 5px 0;
      float: none;
      a {
        font-weight: normal;
        color: $dark-gray;
        text-shadow: none;
        &:hover {
          font-weight: bold;
        }
      }
    }
  }
}