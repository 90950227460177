@import "../../App.scss";

#stickyMenu {
  .navbar {
    display: none;
    align-items: center;
    justify-content: space-between;
    padding: 20px;
    position: absolute;
    z-index: 500;
    width: 100%;
    box-sizing: border-box;
  }

  .navbar-sticky {
    display: flex;
    position: fixed;
    top: 0;
    left: 0;
    animation: moveDown 0.5s ease-in-out;
    background-color: $orange;
    height: 50px;
    @include m {
      display: none;
    }
  }

  .navbar--logo {
    position: absolute;
    top: 5px;
    left: 10px;
    z-index: 110;
    height: 100px;
    width: auto;
    animation: rotate 0.7s ease-in-out 0.5s;
    @include xl {
      top:5px;
      left:10px;
      height: 90px;
    }
    @include l {
      top:5px;
      left:5px;
      height: 70px;
    }

  }
  .menu {
    position: absolute;
    top: 0;
    right: 0;
    z-index: 110;
  @include l {
    font-size: 14px;
  }
  }
  .dividerContainerTop {
    top: unset;
    bottom: -40px;
  }

  @keyframes moveDown {
    from {
      transform: translateY(-5rem);
    }
    to {
      transform: translateY(0rem);
    }
  }

  @keyframes rotate {
    0% {
      transform: rotateY(360deg);
    }
    100% {
      transform: rotateY(0deg);
    }
  }
}
