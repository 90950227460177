@import '../../App.scss';

.menu {
  position: relative;
  z-index: 500;
  li {
    display: inline-block;
    float: left;
    margin-right: 40px;
    cursor: pointer;
    &:last-child {
      margin-right: 0;
    }
    @include l {
      margin-right: 20px;
    }
    a {
      display: inline-block;
      position: relative;
      height: 40px;
      vertical-align: middle;
      text-decoration: none;
      color:#ffffff;
      text-transform: uppercase;
      text-shadow: 0 0 5px #2b2b2b;
      font-weight: bold;
      opacity: .9;
      transition: .5s;
      &:hover {
        opacity: 1;
        text-decoration: none;
        transition: 1s;
      }
      &:before {
        display: inline-block;
        content: "";
        height: 100%;
        vertical-align: middle;
        text-align: right;
      }
    }
    .menuBottomLine {
      display: block;
      box-sizing: content-box;
      margin-top: -5px;
      max-width: 0;
      height: 5px;
      background-color: #ffffff;
      box-shadow: 0 0 5px #2b2b2b;
      transition: max-width .5s;
    }
    &:hover {
      .menuBottomLine {
        max-width: 100%;
        transition: max-width .5s;
      }
    }
  }
}