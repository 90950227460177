@import '../../App.scss';


#sectionTitle{
  display: block;
  width: 100%;
  text-align: center;
  margin-bottom: 20px;
  font-weight: bold;
  color: $dark-gray;
  margin-bottom: 30px;
}

