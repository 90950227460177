@import "../../App.scss";

footer {
  border-top: 7px solid $light-gray;
  background-color: $footer-bg;
  padding: 40px;
  box-sizing: border-box;

  .logoContainer {
    text-align: center;
  }
  .footerSiteSelector {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: center;
    align-items: center;
    align-content: center;
    margin: 20px 0;

    .siteLink {
      flex: 0 1 85px;
      text-align: center;
      margin-right: 10px;
      &:last-child {
        margin-right: 0;
      }

      .cityLogoFooter {
        display: inline-block;
        opacity: 0.5;
        transition: 1s;
        width: 100%;
        max-width: 65px;
        &:hover,
        &.active {
          opacity: 1;
          transition: 1s;
        }
      }
    }
  }
  p {
    text-align: center;
    font-size: 16px;
    color: $light-gray;
  }
}
