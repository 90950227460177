/* fonts */
@font-face {
  font-family: Museo;
  src: url(/fonts/museosans-bold-webfont.eot);
  src: url(/fonts/museosans-bold-webfont.eot?#iefix) format("embedded-opentype"),
    url(/fonts/museosans-bold-webfont.woff2) format("woff2"),
    url(/fonts/museosans-bold-webfont.woff) format("woff"),
    url(/fonts/museosans-bold-webfont.ttf) format("truetype"),
    url(/fonts/museosans-bold-webfont.svg) format("svg");
}
/*variables*/
$maxWidth: 1500px;

/* colors */
$white-background: #f8f8f8;
$orange: #fc6b00;
$dark-gray: #313131;
$light-gray: #656565;
$lighter-gray: #b9b5b5;
$footer-bg: #d9d9d9;

/* break points */
$screen-xxl-min: 1980px;
$screen-xl-min: 1400px;
$screen-l-min: 1199px;
$screen-m-min: 890px;
$screen-s-min: 767px;
$screen-xs-min: 600px;

/* media query mixins */
@mixin xxl {
  @media (max-width: #{$screen-xxl-min}) {
    @content;
  }
}
@mixin xl {
  @media (max-width: #{$screen-xl-min}) {
    @content;
  }
}
@mixin l {
  @media (max-width: #{$screen-l-min}) {
    @content;
  }
}
@mixin m {
  @media (max-width: #{$screen-m-min}) {
    @content;
  }
}
@mixin s {
  @media (max-width: #{$screen-s-min}) {
    @content;
  }
}
@mixin xs {
  @media (max-width: #{$screen-xs-min}) {
    @content;
  }
}

/* default styles */
body {
  font-family: Museo, Arial;
  background-color: $white-background;
}
.ghostButton {
  display: inline-block;
  cursor: pointer;
  padding: 10px 15px;
  text-decoration: none;
  color: #ffffff;
  border: 3px solid #ffffff;
  transition: 1s;
  &:hover {
    text-decoration: none;
    background-color: #ffffff;
    color: #000000;
    transition: 1s;
  }
}
section {
  position: relative;
}
.sectionTitle {
  display: block;
  width: 100%;
  text-align: center;
  font-weight: bold;
  color: $dark-gray;
  margin: 0 0 60px 0;
  font-size: 40px;
  &.subTitle {
    font-size: 26px;
  }
}
.textContainer {
  margin: 60px auto 0 auto;
  max-width: $maxWidth;
  @include s {
    margin: 20px auto 0 auto;
  }
}
p {
  display: block;
  font-size: 19px;
  color: $dark-gray;
  line-height: 28px;
  text-align: justify;
  font-weight: 100;
  margin: 0 0 20px 0;
  @include m {
    font-size: 16px;
  }
}
strong,
.strong {
  font-weight: bold;
}
a {
  text-decoration: none;
  color: $orange;
  transition: 1s;
  &:hover {
    text-decoration: underline;
    transition: 1s;
  }
}

/* curved dividers*/
.dividerContainerTop {
  position: absolute;
  z-index: 100;
  height: 40px;
  width: 100%;
  top: 0px;
  left: 0;

  .rectangleStyle {
    fill: #f8f8f8;
  }

  .lineStyle {
    fill: none;
    stroke: #f36c21;
    stroke-miterlimit: 10;
    stroke-width: 2px;
  }
  &.orange {
    .rectangleStyle {
      fill: $orange;
    }

    .lineStyle {
      fill: none;
      stroke: $light-gray;
      stroke-miterlimit: 10;
      stroke-width: 2px;
    }
  }
}
.dividerContainerBottom {
  position: absolute;
  z-index: 100;
  height: 40px;
  width: 100%;
  bottom: 0;
  left: 0;

  .dividerShapeBottom {
    position: relative;
    overflow: hidden;
    height: 40px;

    &:before {
      border-radius: 100%;
      position: absolute;
      background: $white-background;
      border: 7px solid $light-gray;
      right: -200px;
      left: -200px;
      top: 0px;
      content: "";
      bottom: -120px;
    }
    &.orange {
      &:before {
        border-color: $orange;
      }
    }
    &.whiteBg {
      &:before {
        background-color: #ffffff;
      }
    }
  }
}
