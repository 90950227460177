@import "../../App";

.CarouselContainer {
  position: relative;
  .sectionTitle {
    position: absolute;
    top: -80px;
    left: 0;
    z-index: 100;
    color: $dark-gray;
    display: block;
  }
  .trainingCarousel {
    width: 100%;

  h2 {
    color: $orange;
    @include m {
      text-shadow: 0 0 5px #000000;
    }
    @include s {
      width: 100%;
      text-shadow: 0 0 5px #000000;
      font-size: 16px;
    }
  }

    .slideTextContent {
      padding: 10px;
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      width: 50%;
      opacity: 0.8;
      @include s {
        width: 100%;
        left:0;
        transform: none;
      }

      &.firstSlideContainer {
        bottom: 50px;
        @include m {
          top: 30px;
          background-color: rgba(0,0,0,.5);
        }
        @include s {
          top:0;
          bottom: unset;
        }
      }

      &.secondSlideContainer {
        top: 70px;
        @include m {
          top: 30px;
          background-color: rgba(0,0,0,.5);
        }
        @include s {
          top:0;
        }
      }

      &.thirdSlideContainer {
        top: 70px;
        @include m {
          top: 30px;
          background-color: rgba(0,0,0,.5);
        }
        @include s {
          top:0;
        }
      }
    }

    .carouselSmallText {
      font-size: 20px;
      margin-top: 10px;
      color: white;
      @include s {
        font-size: 14px;
        line-height: 16px;
      }
    }
  }
}
.carousel.carousel-slider .control-arrow {
  width: 25%;
}
.carousel.carousel-slider .control-arrow {
  opacity: .8!important;
  &:hover {
    opacity: 1;
  }
}
.carousel .control-prev.control-arrow::before {
  border-right: none !important;
}
.carousel .control-arrow::before,
.carousel.carousel-slider .control-arrow::before {
  border-top: none !important;
  border-bottom: none !important;
}
.control-arrow.control-prev {
  background: url(../../images/carousel/arrow_left.png) center center no-repeat !important;
  @include m {
    background: none!important;
  }
}
.control-arrow.control-next {
  background: url(../../images/carousel/arrow_right.png) center center no-repeat !important;
  @include m {
    background: none!important;
  }
}
